@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import "assets/stylesheet/scss/variables"; // Variables SCSS

body {
  margin: 0 !important;
  font-family: "Inter", sans-serif !important;
  background: "#F8F5FF !important";
}

#root {
  height: 100vh;
}

.MuiButtonBase-root {
  font-size: 12px !important;
}

.MuiFormHelperText-root {
  margin: 0;
}

.MuiButton {
  &-root {
    min-width: 32px !important;
    text-transform: capitalize !important;
    border: 1px solid #bdbdbd !important;
    font-weight: 600 !important;
    box-shadow: none !important;

    &:hover {
      border: 1px solid #bdbdbd !important;
    }
  }
}

.MuiButton-contained {
  border: 0px solid #7947f6 !important;

  &:hover {
    border: 0px solid #7947f6 !important;
  }
}

.Mui-selected {
  color: #7947f6 !important;
  // color: #ffffff !important;

  &:hover {
    color: #7947f6 !important;
    // color: #ffffff !important; 
  }
}


.MuiTab-textColorPrimary {
  font-size: 16px !important;
  font-weight: bold !important;
  text-transform: inherit !important;

  &:hover {
    font-size: 16px !important;
    font-weight: bold !important;
    text-transform: inherit !important;

  }
}

.MuiTabs-indicator {
  background-color: #7947f6 !important;

  // display: none !important;
  &:hover {
    background-color: #7947f6 !important;
    // display: none !important;

  }
}

.MuiButton-outlined {
  color: #333333 !important;
}

.Mui-disabled {
  color: #d9d9d9 !important;
}

.Mui-disabled {
  .MuiButton-startIcon {
    color: rgba(0, 0, 0, 0.26) !important;
  }
}

.MuiList-root {
  background: "#F8F5FF";
}

/* .MuiButton-startIcon {
  color: #7947f6 !important;
} */

.Mui-disabled {
  .MuiButton-startIcon {
    color: rgba(0, 0, 0, 0.26) !important;
  }

  .MuiInputLabel-asterisk {
    color: rgba(0, 0, 0, 0.26) !important;
  }
}

.MuiAutocomplete-tag {
  margin: 0px 2px 2px 2px;
}

/* .MuiTypography-root {
  font-family: "Inter", sans-serif !important;
} */

.MuiTypography-body1 {
  font-size: 12px !important;
}

.MuiTypography-subtitle2 {
  font-size: 11px !important;
}

.MuiListItemText-root {
  .MuiTypography-body1 {
    font-size: 12px;
  }
}

.MuiListItemAvatar-root {
  min-width: 36px !important;
}

// .MuiSlider-root {
//     color: #20b2aa;
// }

// .MuiSlider-root:hover {
//     color: #2e8b57;
// }

.ag-cell-align-center {
  justify-content: center;
}

.readOnly {
  background: grey !important;
}

.trimText {
  line-height: 18px !important;
  display: inline-flex;
  align-items: center;
  word-break: break-word;

  .ag-cell-wrapper {
    height: 100% !important;
    word-break: normal;
  }
}

// AG theme
.ag-theme-alpine {
  --ag-checkbox-checked-color: #7947f6 !important;
  --ag-input-focus-box-shadow: 0 0 2px 0.1rem #e4d9ff !important;
  //--ag-selected-row-background-color: #FBF9FF !important;
  --ag-selected-row-background-color: #fff4bf !important;
  --ag-alpine-active-color: #7947f6 !important;
  --ag-row-hover-color: #fbf9ff !important;
  --ag-column-hover-color: #fbf9ff !important;
  --ag-checkbox-unchecked-color: #666666 !important;
  --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 1.5) !important;
  --ag-font-family: "Inter", sans-serif !important;

  .ag-row {
    font-size: 12px !important;
  }

  .ag-icon-filter {
    background: transparent url("../../images/filter-icon.svg") center/contain no-repeat;
    color: transparent;
  }

  // .ag-cell-label-container {
  //     padding: 0 8px;
  // }

  // .ag-cell {
  //     padding: 0 15px !important;
  // }

  .ag-header-row:not(:first-child) .ag-header-cell,
  .ag-header-row:not(:first-child) .ag-header-group-cell.ag-header-group-cell-with-group {
    border-top: 2px solid #7947f6 !important;
    background-color: #fbf9ff !important;
  }

  .ag-header-cell-label {
    font-size: 12px !important;
  }

  .ag-input-field-input {
    font-family: "Inter", sans-serif !important;
  }

  .ag-root-wrapper {
    border: 0;
  }

  .ag-header-cell,
  .ag-header-group-cell {}

  .ag-header {
    background-color: $white-color;
    color: $secondary-color;
    font-weight: 500;
    border-bottom: 2px solid #7947f6 !important;

    .ag-header-cell-text,
    .grid-header {
      text-decoration: none;
      left: 10px;
    }

    .sort-type {
      display: inline-block;
      margin-left: 8px;

      &.desc {
        position: relative;
        top: 3px;
        transform: rotate(180deg);
      }
    }
  }

  .ag-row .ag-cell {
    //   background-color: $white-color;
    // display: flex;
    // align-items: center;
    // line-height: 21px;
    // word-break: break-word;
  }

  .ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
    color: #fffff1 !important;
  }

  .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-theme-alpine .ag-ltr .ag-cell-range-single-cell,
  .ag-theme-alpine .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
  .ag-theme-alpine .ag-rtl .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-theme-alpine .ag-rtl .ag-cell-range-single-cell,
  .ag-theme-alpine .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
    border-color: transparent;
  }

  .ag-ltr .ag-floating-filter-button {
    position: relative;
    // right: 15px;
    margin-left: 0;
  }

  // input[class^=ag-][type=text] {
  //     font-family: 'Inter', sans-serif !important;
  // }

  .ag-ltr input[class^="ag-"][type="text"] {
    padding-right: 25px;
    border-color: #d9d9d9;
    border-radius: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    height: 26px;
  }

  .ag-ltr input[class^="ag-"][type="date"] {
    padding-right: 25px;
    border-color: #d9d9d9;
    border-radius: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    height: 26px;
  }

  .active-renderer-container {
    margin-top: -8px;
  }

  .ag-cell-value,
  .ag-group-value {
    overflow: visible;
    text-overflow: ellipsis;

    .dropdown-menu.show {
      padding: 0;

      .dropdown-item {
        line-height: 21px;
      }
    }
  }

  .ag-checkbox-input-wrapper {
    &:active {}
  }

  .ag-ltr {
    .ag-floating-filter-button {
      right: 3px;
      margin-left: -20px;
    }
  }
}

.nav-menu-items {
  //width: 100%;
  // padding: 10px 0 0 0;
  // background-color: grey;

  height: calc(100vh - 65px);
  //overflow-y: auto;
}

.scrollbar-thumb {
  background-color: #666666;
  border-radius: 3px;
  opacity: 0.1;
  z-index: 10;

  &:hover {
    opacity: 0.5;
  }
}