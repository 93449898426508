// Minix CSS Styles
@mixin reset {
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin reset-list {
    @include reset;
    list-style: none;
}
ul{
    @include reset;
}
