.custom-tooltip {
    background: #ffffff;
    border: 0;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
    padding: 3px 10px;
   font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
}

.custom-tooltip p {
    margin: 0px;
    padding: 0px;
}

.label {
    font-weight: 400;

}

.chartTitle {
    font-weight: 500;
}
.chartValue {
    font-weight: 700;
}