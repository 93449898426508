.sideBar {
  z-index: 1200;
  background: #ffffff;
  position: fixed;
  height: 100%;
  width: 114px;
  padding: 0;
  overflow: hidden;
  -moz-box-shadow: 0px 0px 10px 0px rgba(195, 0, 5, 0.1);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(195, 0, 5, 0.1);
  text-align: center;
  box-shadow: 0px 0px 10px 0px rgba(195, 0, 5, 0.1);
  ul {
    margin: 0 7px;
  }
}

.mainContainer {
  height: 100vh;
  width: calc(100% - 120px);
  margin-left: 120px;
  background: url("../../src/assets/images/bg-image.png") #f8f5ff top right
    no-repeat !important;
}
