.multiselect {
  width: 200px;
}

.selectBox {
  position: relative;
}

.selectBox select {
  width: 50%;
  font-weight: bold;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#checkboxes {
  display: none;
  border: 1px #dadada solid;
}

#checkboxes label {
  display: block;
}

#checkboxes label:hover {
  background-color: #1e90ff;
}
.b-contain *,
.b-contain *::before,
.b-contain *::after {
    box-sizing: content-box !important;
}

.b-contain input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.b-contain span {
    line-height: 18px;
    font-size: 0.75rem;
    font-family: inherit;
    vertical-align: middle;
    margin-bottom: 3px;
    display: inline-block;
}

.b-contain {
    display: table;
    position: relative;
    padding-left: 1.5rem;
    cursor: pointer;
    margin-bottom: 0.5rem;
}

.b-contain input[type="checkbox"]~.b-input {
    position: absolute;
    top: 0;
    left: 0;
    height: 0.875rem;
    width: 0.875rem;
    background: #f1f5f9;
    transition: background 250ms;
    border: 1px solid #94a3b8;
    border-radius: 0.2rem;
}

.b-contain input[type="radio"]~.b-input {
    position: absolute;
    top: 0;
    left: 0;
    height: 0.875rem;
    width: 0.875rem;
    background: #f1f5f9;
    transition: background 250ms;
    border: 1px solid #94a3b8;
    border-radius: 3rem;
}

.b-contain input[type="checkbox"]~.b-input::after {
    content: "";
    position: absolute;
    display: none;
    left: 4px;
    top: 0px;
    width: 0.25rem;
    height: 0.5rem;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transition: background 250ms;
    transform: rotate(45deg);
}

.b-contain input[type="radio"]~.b-input::after {
    content: "";
    position: absolute;
    display: none;
    left: 2px;
    top: 2px;
    width: 0.65rem;
    height: 0.6rem;
    border-radius: 3rem;
    background: #ffffff;
    transition: background 250ms;
}

.b-contain input[type="checkbox"]:disabled~.b-input::after {
    border-color: #ffffff;
}

.b-contain input:checked~.b-input::after {
    display: block;
}

.b-contain:hover input[type="checkbox"]:not([disabled])~.b-input,
.b-contain input[type="checkbox"]:focus~.b-input {
    background: rgb(255, 255, 255);
    border-color: #64748b;
}

.b-contain:hover input[type="radio"]:not([disabled])~.b-input,
.b-contain input[type="radio"]:focus~.b-input {
    background: rgb(94, 53, 177);
    border-color: rgb(94, 53, 177);
}

.b-contain input:focus~.b-input {
    box-shadow: 0 0 0 0px #60a5fa;
}

.b-contain input[type="checkbox"]:checked~.b-input {
    background: rgb(94, 53, 177);
    border-color: rgb(94, 53, 177);
}

.b-contain input[type="radio"]:checked~.b-input {
    background: rgb(94, 53, 177);
    border-color: rgb(94, 53, 177);
}

.b-contain input[type="checkbox"]:disabled~.b-input,
.b-contain input[type="radio"]:disabled~.b-input {
    opacity: 0.5;
    cursor: not-allowed;
}

.b-contain input[type="radio"]:disabled~.b-input::after {
    background: #ffffff;
}

.b-contain:hover input[type="checkbox"]:not([disabled]):checked~.b-input,
.b-contain input[type="checkbox"]:checked:focus~.b-input {
    background: rgb(94, 53, 177);
    border-color: rgb(94, 53, 177);
}

.b-contain:hover input[type="radio"]:not([disabled]):checked~.b-input,
.b-contain input[type="radio"]:checked:focus~.b-input {
    background: rgb(94, 53, 177);
    border-color: rgb(94, 53, 177);
}