.spinner-height {
    width: 3rem;
    height: 3rem;
  }
  
  .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f8f8f8ad;
    z-index: 2;
  }
  
  .loader {
    left: 50%;
    top: 45%;
    z-index: 1000;
    position: absolute;
  }